<template>
  <div
    :class="{ hidden: hidden }"
    class="pagination-container"
    style="position: relative;"
  >
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :pager-count="pagerCount"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      next-text="下一页"
      prev-text="上一页"
    />
  </div>
</template>

<script setup>
import { scrollTo } from "@/utils/scroll-to";

const props = defineProps({
  total: {
    required: true,
    type: Number,
  },
  page: {
    type: Number,
    default: 1,
  },
  limit: {
    type: Number,
    default: 20,
  },
  pageSizes: {
    type: Array,
    default() {
      return [10, 20, 30, 50];
    },
  },
  // 移动端页码按钮的数量端默认值5
  pagerCount: {
    type: Number,
    default: document.body.clientWidth < 992 ? 5 : 7,
  },
  layout: {
    type: String,
    default: "prev, pager, next",
  },
  background: {
    type: Boolean,
    default: true,
  },
  autoScroll: {
    type: Boolean,
    default: true,
  },
  hidden: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits();
const currentPage = computed({
  get() {
    return props.page;
  },
  set(val) {
    emit("update:page", val);
  },
});
const pageSize = computed({
  get() {
    return props.limit;
  },
  set(val) {
    emit("update:limit", val);
  },
});
function handleSizeChange(val) {
  if (currentPage.value * val > props.total) {
    currentPage.value = 1;
  }
  emit("pagination", { page: currentPage.value, limit: val });
  if (props.autoScroll) {
    scrollTo(0, 800);
  }
}
function handleCurrentChange(val) {
  emit("pagination", { page: val, limit: pageSize.value });
  if (props.autoScroll) {
    scrollTo(0, 800);
  }
}
</script>

<style scoped lang="scss">
.pagination-container {
  height: 40px;
  margin: 0;
  padding: 40px 0 30px !important;
  background: #fff;
  text-align: center;
  box-sizing: content-box;
  :deep(.btn-prev), :deep(.btn-next) {
    border: 1px solid #EDEDED;
    padding: 4px 12px;
    &:hover {
      color: #3d3d3d;
      background: #F6F2FA;
      border-radius: 0;
    }
  }
  :deep(.btn-prev) {
    //border-right: none;
    border-radius: 4px 0 0 4px;
  }
  :deep(.btn-next) {
    border-left: none;
    border-radius: 0 4px 4px 0;
  }
  :deep(.el-pager) {
    //border: 1px solid #ddd;
    border-right: none;
    border-left: none;
    li {
      border: 1px solid #EDEDED;
      border-left: none;
      border-radius: 0;
      &:hover {
        color: #3d3d3d;
        background: #F6F2FA;
        border-radius: 0;
        //border: 1px solid #8F56F1;
      }
      &.is-active {
        color: #fff;
        background: linear-gradient( 90deg, #BE74D7 0%, #8F58EA 99%);
        border: none;
      }
    }
  }
}
.el-pagination {
  width: 100%;
  justify-content: center;
}
.pagination-container.hidden {
  display: none;
}
</style>
