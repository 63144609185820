<template>
  <svg :class="svgClass" :style="{ width: iconClass ? '1.2em' : 0 }" aria-hidden="true">
    <use :xlink:href="iconName" :fill="color" />
  </svg>
</template>

<script>
export default defineComponent({
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    return {
      iconName: computed(() => `#icon-${props.iconClass}`),
      svgClass: computed(() => {
        if (props.className) {
          return `svg-icon ${props.className}`
        }
        return 'svg-icon'
      })
    }
  }
})
</script>

<style lang="scss">
.width0 {
  width: 0 !important;
}
.sub-el-icon,
.nav-icon {
  display: inline-block;
  font-size: 15px;
  margin-right: 12px;
  position: relative;
}

.svg-icon {
  width: 1.2em;
  height: 1.2em;
  position: relative;
  fill: #909090;
  vertical-align: -2px;
}
.rect1 {
  fill: var(--color-rect1, #909090);
}
.rect2 {
  stroke: var(--color-rect2, #909090);
  stroke-width: var(--stroke-width, 12);
}
.el-menu-item.is-active {
  .rect1 {
    fill: var(--color-rect1, #fff);
  }
  .rect2 {
    stroke: var(--color-rect2, #fff);
    stroke-width: var(--stroke-width, 12);
  }
}
</style>
