<template>
  <section class="app-main">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <router-view v-slot="{ Component, route }">
        <transition name="fade-transform" mode="out-in">
          <div :key="route.path">
            <keep-alive :include="tagsViewStore.cachedViews">
              <component
                v-if="!route.meta.link"
                :is="Component"
                :key="route.path"
              />
            </keep-alive>
          </div>
        </transition>
      </router-view>
    </el-scrollbar>
    <iframe-toggle />
  </section>
</template>

<script setup>
import iframeToggle from "./IframeToggle/index";
import useTagsViewStore from "@/store/modules/tagsView";

const tagsViewStore = useTagsViewStore();
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  // height: calc(100% - 50px);
  height: 100%;

  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f7f8fa;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 17px;
  }
}
</style>
